import React, { useRef, useEffect, useState, memo } from 'react';
import { createChart } from 'lightweight-charts';
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from 'prop-types';

const PerformanceComponent = ({ account, interval }) => {
  const chartContainerRef = useRef();
  const chartRef = useRef(null); // Reference for the chart instance
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const calculateIntervalParams = (interval) => {
    const today = new Date();
    let intervalParam = 'default';
    let dateParam = '';

    switch (interval) {
      case 'Weekly':
        intervalParam = 'week';
        // Calculate date four Sundays ago
        const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
        dateParam = new Date(lastSunday.setDate(lastSunday.getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Monthly':
        intervalParam = 'month';
        // Calculate date on the first day of six months ago
        const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 1));
        sixMonthsAgo.setDate(1); // Set to the first day of the month
        dateParam = sixMonthsAgo.toISOString().split('T')[0];
        break;
      case 'Yearly':
        intervalParam = 'year';
        // Fixed date on the first day of 2022
        dateParam = '2024-01-01';
        break;
      default:
        intervalParam = 'default';
        dateParam = '';
    }

    return { intervalParam, dateParam };
  };

  const fetchPerformanceData = async () => {
    if (!isAuthenticated) return;

    setLoading(true); // Start loader
    setData([]); // Clear previous data
    if (chartRef.current) {
      chartRef.current.remove(); // Remove the previous chart
      chartRef.current = null;
    }

    const { intervalParam, dateParam } = calculateIntervalParams(interval);

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${window.location.host}/api/`,
          scope: "read:performance",
        },
      });

      const response = await fetch(`/api/performance`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account: account || null,
          interval: intervalParam,
          date: dateParam,
        }),
      });

      if (!response.ok) throw new Error('Failed to fetch performance data.');

      const result = await response.json();
      setData(result.data || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching performance data:', err);
      setError(err.message || 'Error loading data.');
    } finally {
      setLoading(false); // Stop loader
    }
  };

  useEffect(() => {
    fetchPerformanceData(); // Trigger fetch when account or interval changes
  }, [isAuthenticated, getAccessTokenSilently, account, interval]);

  useEffect(() => {
    if (!data.length || loading) return;

    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#333',
      },
      width: chartContainerRef.current.offsetWidth,
      height: chartContainerRef.current.offsetHeight,
      rightPriceScale: {
        scaleMargins: { top: 0.1, bottom: 0.1 },
      },
      timeScale: {
        borderColor: '#cccccc',
        fixLeftEdge: true,
        fixRightEdge: true,
        barSpacing: Math.max(chartContainerRef.current.offsetWidth / (data.length * 3), 10),
      },
    });

    const series = chartRef.current.addHistogramSeries({
      priceFormat: { type: 'percent' },
      color: '#26a69a',
    });

    series.setData(
      data.map(({ date, performance }) => ({
        time: date,
        value: performance * 100, // Convert to percentage
        color: performance >= 0 ? '#26a69a' : '#d32f2f', // Green for positive, red for negative
      }))
    );

    // Resize handling for both width and height
    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      chartRef.current.applyOptions({
        width,
        height,
        timeScale: {
          barSpacing: Math.max(width / (data.length * 3), 10),
        },
      });
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [data, loading]);

  return (
    <div className="position-relative" style={{ width: '100%', height: '100%' }}>
      {/* Loading Spinner */}
      {loading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100">
          <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        </div>
      )}

      {/* Chart Container */}
      <div
        ref={chartContainerRef}
        style={{ height: '100%', minHeight: '300px', width: '100%' }}
      ></div>

      {/* Error Message */}
      {error && !loading && (
        <div className="alert alert-danger mt-3 text-center">
          {error}
        </div>
      )}

      {/* No Data Placeholder */}
      {!loading && !data.length && !error && (
        <div className="text-center text-muted mt-3">
          <p>No performance data available.</p>
        </div>
      )}
    </div>
  );
};

PerformanceComponent.propTypes = {
  account: PropTypes.string,
  interval: PropTypes.string.isRequired,
};

PerformanceComponent.defaultProps = {
  account: null,
};

export default memo(PerformanceComponent);
