import React, { useState } from 'react';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';
import TransactionsComponent from './TransactionsComponent';
import Toolbar from './Toolbar';
import dayjs from 'dayjs';

const Dashboard = () => {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD')); // Set default date to today
  const [option, setOption] = useState(''); // State for account selection

  return (
    <div>
      <Toolbar date={date} setDate={setDate} option={option} setOption={setOption} />
      <div className="container p-4" style={{ marginTop: '60px' }}> {/* Adjust margin to clear Navbar and Toolbar */}

        {/* Row for Chart and Table components */}
        <div className="row mb-4 g-4"> {/* Added g-4 for consistent gutter spacing */}
          <div className="col-12 col-lg-6">
            <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title" style={{ fontWeight: 'bold', color: '#333' }}>Chart</h5>
                <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{ padding: '10px' }}>
                  <ChartComponent option={option} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title" style={{ fontWeight: 'bold', color: '#333' }}>Table</h5>
                <div className="flex-grow-1" style={{ padding: '10px' }}>
                  <TableComponent date={date} option={option} /> {/* Pass date and option as props */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Row for Transactions component */}
        <div className="row g-4">
          <div className="col-12">
            <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title" style={{ fontWeight: 'bold', color: '#333' }}>Transactions</h5>
                <div className="flex-grow-1" style={{ padding: '10px', overflowY: 'auto', maxHeight: '500px' }}>
                  <TransactionsComponent />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Dashboard;