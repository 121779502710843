import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useAuth0 } from "@auth0/auth0-react";

function TransactionsComponent({ option, setTransactionOptions, selectedTransactionOptions }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!isAuthenticated) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${window.location.host}/api/`,
            scope: "read:transactions",
          },
        });

        // Build query string
        const params = new URLSearchParams();
        if (option) params.append('accountId', option);

        const response = await fetch(`/api/transactions?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch transactions.');
        }

        const data = await response.json();
        setTransactions(data.data); // `data.data` assumes the API wraps results in a `data` field

        // Extract dynamic column keys from the first transaction
        if (data.data.length > 0) {
          const columnKeys = Object.keys(data.data[0]);
          setTransactionOptions(columnKeys); // Populate dropdown with column keys
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [isAuthenticated, option, getAccessTokenSilently, setTransactionOptions]); // Re-fetch when option changes

  // Define all possible columns
  const allColumns = [
    { key: 'id', name: 'Transaction ID', selector: row => row.id, sortable: true },
    { key: 'position_id', name: 'Position ID', selector: row => row.position_id, sortable: true },
    { key: 'account_id', name: 'Account ID', selector: row => row.account_id, sortable: true },
    {
      key: 'time_stamp',
      name: 'Timestamp',
      selector: row => row.time_stamp ? new Date(row.time_stamp).toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => new Date(a.time_stamp) - new Date(b.time_stamp),
    },
    { key: 'type', name: 'Type', selector: row => row.type, sortable: true },
    {
      key: 'from_instrument_name',
      name: 'From Instrument Name',
      selector: row => row.from_instrument_name,
      sortable: true,
    },
    {
      key: 'from_amount',
      name: 'From Amount',
      selector: row => row.from_amount,
      format: row => row.from_amount != null ? row.from_amount.toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => (a.from_amount || 0) - (b.from_amount || 0),
    },
    {
      key: 'to_instrument_name',
      name: 'To Instrument Name',
      selector: row => row.to_instrument_name,
      sortable: true,
    },
    {
      key: 'to_amount',
      name: 'To Amount',
      selector: row => row.to_amount,
      format: row => row.to_amount != null ? row.to_amount.toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => (a.to_amount || 0) - (b.to_amount || 0),
    },
  ];

  // Filter columns based on selectedTransactionOptions
  const filteredColumns = allColumns.filter(column =>
    selectedTransactionOptions.includes(column.key)
  );

  return (
    <div>
      {loading ? (
        <div className="text-center p-4">Loading transactions...</div>
      ) : error ? (
        <div className="text-center p-4 text-danger">Error loading transactions: {error.message}</div>
      ) : (
        <DataTable
          columns={filteredColumns} // Display only selected columns
          data={transactions}
          pagination
          progressPending={loading}
          noDataComponent="No transactions found."
        />
      )}
    </div>
  );
}

export default TransactionsComponent;
