import React, { useState, useRef, useEffect } from 'react';
import "./SettingsDropdown.css"

const SettingsDropdown = ({ show, onClose, options, onSettingsChange }) => {
  const dropdownRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Close dropdown on clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  // Initialize selected options
  useEffect(() => {
    setSelectedOptions(options); // Select all options by default
    onSettingsChange(options);  // Notify parent
  }, [options, onSettingsChange]);

  // Toggle individual option
  const toggleOption = (option) => {
    const updatedSelections = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedSelections);
    onSettingsChange(updatedSelections);
  };

  if (!show) return null;

  return (
    <div
      ref={dropdownRef}
      className="dropdown-menu p-3 shadow-sm rounded-3"
      style={{
        display: 'block', // Ensure visibility even though it's positioned with Bootstrap
        minWidth: '30px', // Wider dropdown for better readability
        marginTop: '25px', // Add spacing below the cog
        right: 0, // Align to the right
      }}
    >
      <h6 className="dropdown-header fw-bold text-secondary mb-2">Settings</h6>
      <ul className="list-unstyled m-0">
        {options.map((option) => (
          <li key={option} className="d-flex align-items-center mb-2">
            <input
              type="checkbox"
              id={`checkbox-${option}`}
              className="form-check-input me-2"
              checked={selectedOptions.includes(option)}
              onChange={() => toggleOption(option)}
            />
            <label htmlFor={`checkbox-${option}`} className="form-check-label flex-grow-1">
              {option}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsDropdown;
