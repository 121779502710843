import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useAuth0 } from "@auth0/auth0-react";

function TransactionsComponent() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!isAuthenticated) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${window.location.host}/api/`,
            scope: "read:transactions",
          },
        });

        const response = await fetch('/api/transactions', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch transactions.');
        }

        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [isAuthenticated, getAccessTokenSilently]);

  const columns = [
    { name: 'Transaction ID', selector: row => row.id, sortable: true },
    { name: 'Position ID', selector: row => row.position_id, sortable: true },
    { name: 'Account ID', selector: row => row.account_id, sortable: true },
    {
      name: 'Timestamp',
      selector: row => row.time_stamp ? new Date(row.time_stamp).toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => new Date(a.time_stamp) - new Date(b.time_stamp),
    },
    { name: 'Type', selector: row => row.type, sortable: true },
    { name: 'From Instrument Name', selector: row => row.from_instrument_name, sortable: true },
    {
      name: 'From Amount',
      selector: row => row.from_amount,
      format: row => row.from_amount != null ? row.from_amount.toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => (a.from_amount || 0) - (b.from_amount || 0),
    },
    { name: 'To Instrument Name', selector: row => row.to_instrument_name, sortable: true },
    {
      name: 'To Amount',
      selector: row => row.to_amount,
      format: row => row.to_amount != null ? row.to_amount.toLocaleString() : 'N/A',
      sortable: true,
      sortFunction: (a, b) => (a.to_amount || 0) - (b.to_amount || 0),
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="text-center p-4">Loading transactions...</div>
      ) : error ? (
        <div className="text-center p-4 text-danger">Error loading transactions: {error.message}</div>
      ) : (
        <DataTable
          columns={columns}
          data={transactions}
          pagination
          progressPending={loading}
          noDataComponent="No transactions found."
        />
      )}
    </div>
  );
}

export default TransactionsComponent;