import React, { useRef, useEffect, useState, memo } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import { calculateDateRange } from './DateRangeSelector'; // Import the reusable function

const ChartComponent = ({ account, stock, dateRange = '1M', setChartOptions, selectedChartOptions }) => {
  const chartContainerRef = useRef();
  const chartRef = useRef(null); // To hold the chart instance
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get date range using imported calculateDateRange
  const { fromDate, toDate } = calculateDateRange(dateRange);

  // Fetch data
  const fetchData = async () => {
    if (!isAuthenticated) return;

    setLoading(true); // Start loader
    setData([]); // Clear previous data to reset the chart
    if (chartRef.current) {
      chartRef.current.remove(); // Remove the previous chart
      chartRef.current = null;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${window.location.host}/api/`,
          scope: "read:current_user",
        },
      });

      const response = await fetch(
        `/api/total?from=${fromDate}&to=${toDate}&account=${account || ''}&asset=${stock || 'Total'}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (!response.ok) throw new Error('Failed to fetch data');

      const result = await response.json();
      setData(result);

      // Dynamically set chart options (column names excluding "time")
      const keys = result.length > 0 ? Object.keys(result[0]).filter((key) => key !== 'time') : [];
      setChartOptions(keys);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message || 'Error loading chart data.');
    } finally {
      setLoading(false); // Stop loader
    }
  };

  // Trigger data fetching when dependencies change
  useEffect(() => {
    fetchData(); // Call fetchData when dependencies change
  }, [account, stock, dateRange, isAuthenticated, fromDate, toDate]);

  // Chart rendering logic
  useEffect(() => {
    if (data.length === 0 || selectedChartOptions.length === 0 || loading) return;

    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#333',
      },
      width: chartContainerRef.current.offsetWidth,
      height: chartContainerRef.current.offsetHeight,
      rightPriceScale: { scaleMargins: { top: 0.1, bottom: 0.1 } },
      timeScale: { borderColor: '#cccccc', fixLeftEdge: true, fixRightEdge: true },
    });

    // Add dynamic line series for selected options
    const lineSeriesMap = {};
    selectedChartOptions.forEach((option, index) => {
      const color = hslToRgb(((index + 2) * 120) % 360, 100, 50); // Unique color for each line

      lineSeriesMap[option] = chartRef.current.addLineSeries({
        color,
        lineWidth: 2,
        lineStyle: index === 0 ? LineStyle.Solid : LineStyle.Dashed,
      });

      lineSeriesMap[option].setData(
        data.map((item) => ({
          time: item.time,
          value: parseFloat(item[option]),
        }))
      );
    });

    // Fit all data points into the visible chart area
    chartRef.current.timeScale().fitContent();

    // Handle chart resizing
    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      chartRef.current.applyOptions({ width, height });
      chartRef.current.timeScale().fitContent(); // Ensure data remains visible after resizing
    });
    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [data, selectedChartOptions, loading]);

  return (
    <div className="position-relative" style={{ width: '100%', height: '100%' }}>
      {/* Loading Spinner */}
      {loading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100">
          <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        </div>
      )}

      {/* Chart Container */}
      <div ref={chartContainerRef} style={{ height: '100%', minHeight: '300px', width: '100%' }}></div>

      {/* Error Message */}
      {error && !loading && (
        <div className="alert alert-danger mt-3 text-center">
          {error}
        </div>
      )}

      {/* No Data Placeholder */}
      {!loading && !data.length && !error && (
        <div className="text-center text-muted mt-3">
          <p>No chart data available.</p>
        </div>
      )}
    </div>
  );
};

ChartComponent.propTypes = {
  account: PropTypes.string,
  stock: PropTypes.string,
  dateRange: PropTypes.string,
  setChartOptions: PropTypes.func.isRequired,
  selectedChartOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ChartComponent.defaultProps = {
  account: null,
  stock: null,
  dateRange: '1M',
};

// Helper function to convert HSL to RGB
const hslToRgb = (h, s, l) => {
  s /= 100;
  l /= 100;

  const k = (n) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n) =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

  return `rgb(${Math.round(255 * f(0))}, ${Math.round(255 * f(8))}, ${Math.round(
    255 * f(4)
  )})`;
};

export default memo(ChartComponent);
