import React, { useRef, useEffect, useState } from 'react';
import { createChart } from 'lightweight-charts';

function ChartComponent({ option }) {
  const chartContainerRef = useRef();
  const [data, setData] = useState([]);

  // Calculate dynamic dates
  const toDate = new Date().toISOString().split('T')[0];
  const fromDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

  // Function to fetch data from /api/total endpoint
  const fetchData = async (optionParam) => {
    try {
      const response = await fetch(`/api/total?from=${fromDate}&to=${toDate}&account=${optionParam || ''}`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data whenever date or option changes
  useEffect(() => {
    fetchData(option);
  }, [option]);

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, { width: 400, height: 300 });
    const lineSeries = chart.addLineSeries();
    lineSeries.setData(
      data.map(item => ({
        time: item.time,
        value: parseFloat(item.value),
      }))
    );

    // Function to adjust chart size and fit content
    const resizeChart = () => {
      if (chartContainerRef.current) {
        const { width, height } = chartContainerRef.current.getBoundingClientRect();
        chart.applyOptions({ width, height });
        chart.timeScale().fitContent();
      }
    };

    // Initial resize to fit container
    resizeChart();

    // Add resize observer for responsive adjustments
    const resizeObserver = new ResizeObserver(() => {
      resizeChart();
    });
    resizeObserver.observe(chartContainerRef.current);

    // Clean up chart and observer on component unmount
    return () => {
      chart.remove();
      resizeObserver.disconnect();
    };
  }, [data]);

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
      {data.length === 0 && <p>Loading chart data...</p>}
    </div>
  );
}

export default ChartComponent;
