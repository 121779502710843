// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glow-bar {
  position: fixed;
  /* Fix the bar at the top of the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Thickness of the glow bar */
  background: linear-gradient(270deg,
      #ff0000,
      #00ff00,
      #0000ff,
      #ff0000);
  background-size: 600% 600%;
  /* Enlarged background for smooth animation */
  animation: moveGradient 10s linear infinite;
  /* Animation properties */
  z-index: 1051;
  /* Ensure it's above the navbar */
}

@keyframes moveGradient {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/GlowBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2CAA2C;EAC3C,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,8BAA8B;EAC9B;;;;cAIY;EACZ,0BAA0B;EAC1B,6CAA6C;EAC7C,2CAA2C;EAC3C,yBAAyB;EACzB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".glow-bar {\n  position: fixed;\n  /* Fix the bar at the top of the viewport */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 2px;\n  /* Thickness of the glow bar */\n  background: linear-gradient(270deg,\n      #ff0000,\n      #00ff00,\n      #0000ff,\n      #ff0000);\n  background-size: 600% 600%;\n  /* Enlarged background for smooth animation */\n  animation: moveGradient 10s linear infinite;\n  /* Animation properties */\n  z-index: 1051;\n  /* Ensure it's above the navbar */\n}\n\n@keyframes moveGradient {\n  0% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0% 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
