import React, { useRef, useEffect, useState, memo } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { calculateDateRange } from './DateRangeSelector'; // Import date range helper

const AssetComponent = ({ stock, dateRange = 'YTD', setChartOptions, selectedChartOptions }) => {
  const chartContainerRef = useRef();
  const chartRef = useRef(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { fromDate, toDate } = calculateDateRange(dateRange);

  // Fetch data from API
  const fetchData = async () => {
    if (!isAuthenticated) return;

    setLoading(true);
    setData([]);

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${window.location.host}/api/`,
          scope: 'read:current_user',
        },
      });

      const response = await fetch(
        `/api/quote?from=${fromDate}&to=${toDate}&stock=${stock}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (!response.ok) throw new Error('Failed to fetch data');

      const result = await response.json();
      console.log('API Response:', result);

      const quotes = result.quotes || [];
      const transformedData = quotes.map((item) => ({
        time: new Date(item.date).toISOString().split('T')[0], // YYYY-MM-DD
        open: parseFloat(item.open),
        close: parseFloat(item.close),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        volume: parseFloat(item.volume),
      }));

      setData(transformedData);

      // Only set chart options if they haven't been set yet
      if (selectedChartOptions.length === 0) {
        setChartOptions(['candlestick', 'line']);
      }

      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message || 'Error loading asset data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [stock, dateRange, isAuthenticated, fromDate, toDate]);

  useEffect(() => {
    if (data.length === 0 || loading) return;

    const chartType = selectedChartOptions[0] || 'candlestick';

    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#333',
      },
      width: chartContainerRef.current.offsetWidth,
      height: chartContainerRef.current.offsetHeight,
      rightPriceScale: { scaleMargins: { top: 0.1, bottom: 0.1 } },
      timeScale: { borderColor: '#cccccc', fixLeftEdge: true, fixRightEdge: true },
    });

    if (chartType === 'candlestick') {
      // Render candlestick chart
      const candlestickSeries = chartRef.current.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderUpColor: '#26a69a',
        borderDownColor: '#ef5350',
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
      });

      candlestickSeries.setData(
        data.map(({ time, open, close, high, low }) => ({
          time,
          open,
          high,
          low,
          close,
        }))
      );
    } else if (chartType === 'line') {
      // Render line chart
      const lineSeries = chartRef.current.addLineSeries({
        color: '#2196f3',
        lineStyle: LineStyle.Solid,
        lineWidth: 2,
      });

      lineSeries.setData(
        data.map(({ time, close }) => ({
          time,
          value: close,
        }))
      );
    }

    chartRef.current.timeScale().fitContent();

    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      chartRef.current.applyOptions({ width, height });
      chartRef.current.timeScale().fitContent();
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [data, loading, selectedChartOptions]);

  return (
    <div className="position-relative" style={{ width: '100%', height: '100%' }}>
      {loading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100">
          <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        </div>
      )}
      <div ref={chartContainerRef} style={{ height: '100%', minHeight: '300px', width: '100%' }}></div>
      {error && !loading && (
        <div className="alert alert-danger mt-3 text-center">{error}</div>
      )}
      {!loading && !data.length && !error && (
        <div className="text-center text-muted mt-3">
          <p>No chart data available for the selected range.</p>
        </div>
      )}
    </div>
  );
};

AssetComponent.propTypes = {
  stock: PropTypes.string.isRequired,
  dateRange: PropTypes.string,
  setChartOptions: PropTypes.func.isRequired,
  selectedChartOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AssetComponent.defaultProps = {
  dateRange: 'YTD',
};

export default memo(AssetComponent);
