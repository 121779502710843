import React, { useState, useRef } from 'react';
import SettingsDropdown from './SettingsDropdown';

const CardComponent = ({
  title,
  children,
  footer,
  onFullscreen,
  isFullscreen,
  settingsOptions,
  onSettingsChange // Callback to pass changes upward
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const cogRef = useRef(null);

  const toggleSettings = () => {
    setShowSettings((prev) => !prev);
  };

  return (
    <div className="card h-100 shadow-sm rounded position-relative">
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title fw-bold mb-0">{title}</h5>
          <div className="d-flex gap-3 position-relative">
            {/* Settings (Cog) Icon */}
            <i
              ref={cogRef}
              className="bi bi-gear"
              style={{
                cursor: 'pointer',
                transition: 'transform 0.2s, color 0.2s',
              }}
              title="Settings"
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.2)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
              onClick={toggleSettings} // Toggle settings visibility
            />
            {/* Fullscreen Toggle Icon */}
            <i
              className={`bi ${isFullscreen ? 'bi-fullscreen-exit' : 'bi-arrows-fullscreen'}`}
              style={{
                cursor: 'pointer',
                transition: 'transform 0.2s, color 0.2s',
              }}
              title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.2)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
              onClick={onFullscreen}
            />
            {/* Inline Settings Dropdown */}
            <SettingsDropdown
              show={showSettings}
              onClose={() => setShowSettings(false)}
              options={settingsOptions} // Pass options to the dropdown
              onSettingsChange={onSettingsChange} // Handle changes
            />
          </div>
        </div>
        <div className="flex-grow-1">{children}</div>
        {footer && <div className="mt-3">{footer}</div>}
      </div>
    </div>
  );
};

export default CardComponent;
