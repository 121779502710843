import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div
      style={{
        width: isSidebarOpen ? '250px' : '0',
        height: '100vh',
        padding: isSidebarOpen ? '1rem' : '0',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1050,
        overflowY: 'auto',
        transition: 'width 0.3s ease, padding 0.3s ease',
        backgroundColor: '#ffffff',
        boxShadow: isSidebarOpen ? '2px 0 8px rgba(0, 0, 0, 0.1)' : 'none',
        borderRight: isSidebarOpen ? '1px solid #e0e0e0' : 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <button
        onClick={toggleSidebar}
        style={{
          border: 'none',
          background: 'none',
          fontSize: '20px',
          color: '#333',
          marginBottom: '1rem',
          cursor: 'pointer',
          alignSelf: 'flex-end',
        }}
        aria-label="Close sidebar"
      >
        &larr;
      </button>

      <Link
        className="navbar-brand mb-4"
        to="/"
        style={{
          fontSize: '1.25rem',
          color: '#2c3e50',
          textDecoration: 'none',
          fontWeight: 'bold',
          display: 'block',
          textAlign: 'center',
          padding: '0.5rem 0',
        }}
      >
        MyApp
      </Link>

      <hr style={{ border: '0.5px solid #e0e0e0', width: '100%', margin: '1rem 0' }} />

      <ul className="nav flex-column" style={{ listStyleType: 'none', padding: 0 }}>
        <li className="nav-item mb-2">
          <Link
            className="nav-link"
            to="/"
            style={{
              color: '#555',
              textDecoration: 'none',
              padding: '10px 0',
              fontSize: '0.95rem',
              transition: 'color 0.2s ease',
            }}
          >
            Home
          </Link>
        </li>
        <li className="nav-item mb-2">
          <Link
            className="nav-link"
            to="/dashboard"
            style={{
              color: '#555',
              textDecoration: 'none',
              padding: '10px 0',
              fontSize: '0.95rem',
              transition: 'color 0.2s ease',
            }}
          >
            Dashboard
          </Link>
        </li>
        {isAuthenticated && (
          <>
            <li className="nav-item mb-2">
              <Link
                className="nav-link"
                to="/assets"
                style={{
                  color: '#555',
                  textDecoration: 'none',
                  padding: '10px 0',
                  fontSize: '0.95rem',
                  transition: 'color 0.2s ease',
                }}
              >
                Assets
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link
                className="nav-link"
                to="/import"
                style={{
                  color: '#555',
                  textDecoration: 'none',
                  padding: '10px 0',
                  fontSize: '0.95rem',
                  transition: 'color 0.2s ease',
                }}
              >
                Import
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link
                className="nav-link"
                to="/profile"
                style={{
                  color: '#555',
                  textDecoration: 'none',
                  padding: '10px 0',
                  fontSize: '0.95rem',
                  transition: 'color 0.2s ease',
                }}
              >
                Profile
              </Link>
            </li>
          </>
        )}
      </ul>

      <div style={{ marginTop: 'auto', paddingTop: '1rem', borderTop: '1px solid #e0e0e0' }}>
        {!isAuthenticated ? (
          <LoginButton />
        ) : (
          <LogoutButton />
        )}
      </div>
    </div>
  );
};

export default Sidebar;