import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useAuth0 } from "@auth0/auth0-react";

function TableComponent({ date, option, setStockOptions, setTableColumns, selectedColumns }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchData = async (dateParam, optionParam) => {
    if (!isAuthenticated) return;

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${window.location.host}/api/`,
          scope: "read:current_user",
        },
      });

      const response = await fetch(`/api/portfolio?date=${dateParam || ''}&account=${optionParam || ''}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch data');

      const result = await response.json();
      setData(result);

      // Extract column names dynamically from the response
      if (result.length > 0) {
        // Extract unique "Asset" values and update the stock dropdown options
        const uniqueAssets = [...new Set(result.map(item => item.asset))];
        setStockOptions(['Total', ...uniqueAssets]); // Include "Total" as default

        const columnNames = Object.keys(result[0]); // Get keys from the first row of the data
        setTableColumns(columnNames); // Update dropdown options with column names
      }

    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(date, option);
  }, [date, option, isAuthenticated]);

  // Dynamically filter columns based on selectedColumns
  useEffect(() => {
    const allColumns = [
      {
        name: 'Asset',
        selector: row => row.asset,
        sortable: true,
        id: 'asset',
      },
      {
        name: 'Units',
        selector: row => row.units,
        sortable: true,
        id: 'units',
      },
      {
        name: 'Invested',
        selector: row => row.invested,
        sortable: true,
        id: 'invested',
      },
      {
        name: 'Value',
        selector: row => row.value,
        sortable: true,
        id: 'value',
      },
      {
        name: 'Weight',
        selector: row => row.weight,
        sortable: true,
        id: 'weight',
      },
    ];

    // Filter columns based on selectedColumns
    const updatedColumns = allColumns.filter(column =>
      selectedColumns.includes(column.id)
    );

    setFilteredColumns(updatedColumns);
  }, [selectedColumns]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div style={{ color: 'red' }}>Error: {error}</div>
      ) : (
        <DataTable
          columns={filteredColumns} // Render filtered columns
          data={data}
          noDataComponent="No data found."
          pagination
        />
      )}
    </div>
  );
}

export default TableComponent;
