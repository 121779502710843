import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import Home from './Home';
import Dashboard from './Dashboard';
import ImportComponent from './ImportComponent';
import Login from './Login';
import Profile from './Profile';
import GlowBar from './GlowBar';
import Assets from './Assets'; // Import Assets component

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Router>
      <div>
        <div style={{ display: 'flex' }}>
          <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
          <div style={{ flexGrow: 1, marginLeft: isSidebarOpen ? '250px' : '0' }}>
            <NavBar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
            <GlowBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assets"
                element={
                  <ProtectedRoute>
                    <Assets />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/import"
                element={
                  <ProtectedRoute>
                    <ImportComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
