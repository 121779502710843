import React from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import dayjs from 'dayjs';

const Toolbar = ({ date, setDate, option, setOption }) => {
  const handlePreviousDay = () => {
    setDate(dayjs(date).subtract(1, 'day').format('YYYY-MM-DD'));
  };

  const handleNextDay = () => {
    setDate(dayjs(date).add(1, 'day').format('YYYY-MM-DD'));
  };

  return (
    <div
      className="toolbar navbar navbar-expand-lg navbar-light bg-light"
      style={{
        borderBottom: '1px solid #ddd',
        padding: '6px',
        position: 'fixed',
        top: '56px',
        width: '100%',
        zIndex: 1029,
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          maxWidth: '1000px', // Fixed width for centered alignment
          margin: '0 auto',   // Centers the toolbar content
          gap: '12px',
          marginTop: '4px',
        }}
      >
        {/* Account Selection */}
        <DropdownButton
          id="account-dropdown"
          title={option === "" ? "All accounts" : option}
          onSelect={(eventKey) => setOption(eventKey)}
          variant="light"
          style={{
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            padding: '0.3rem 0.6rem',
            fontSize: '0.8rem',
            margin: '0 4px',
          }}
        >
          <Dropdown.Item eventKey="">All accounts</Dropdown.Item>
          <Dropdown.Item eventKey="0">Trading 212</Dropdown.Item>
          <Dropdown.Item eventKey="1">eToro</Dropdown.Item>
          <Dropdown.Item eventKey="2">Interactive Brokers</Dropdown.Item>
        </DropdownButton>

        {/* Separator */}
        <div style={{ width: '1px', height: '25px', backgroundColor: '#ddd', margin: '0 8px' }} />

        {/* Date Navigation and Selection */}
        <div className="date-picker d-flex align-items-center" style={{ gap: '8px', padding: '0 4px' }}>
          <Button
            variant="outline-secondary"
            onClick={handlePreviousDay}
            style={{
              borderRadius: '50%',
              width: '28px',
              height: '28px',
              padding: 0,
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
            }}
          >
            {"‹"}
          </Button>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-control text-center"
            style={{
              width: '130px',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              textAlign: 'center',
              border: '1px solid #ddd',
              padding: '0.3rem 0.5rem',
              fontSize: '0.8rem',
              margin: '0 4px',
            }}
          />
          <Button
            variant="outline-secondary"
            onClick={handleNextDay}
            style={{
              borderRadius: '50%',
              width: '28px',
              height: '28px',
              padding: 0,
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
            }}
          >
            {"›"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;