import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

function TableComponent({ date, option }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch data with date and option parameters
  const fetchData = async (dateParam, optionParam) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`/api/portfolio?date=${dateParam || ''}&account=${optionParam || ''}`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data whenever date or option changes
  useEffect(() => {
    fetchData(date, option);
  }, [date, option]);

  const columns = [
    {
      name: 'Asset',
      selector: row => row.asset,
      sortable: true,
    },
    {
      name: 'Units',
      selector: row => row.units,
      sortable: true,
      sortFunction: (a, b) => (a.units || 0) - (b.units || 0),
    },
    {
      name: 'Value',
      selector: row => row.value,
      sortable: true,
      sortFunction: (a, b) => (a.value || 0) - (b.value || 0),
    },
    {
      name: 'Weight',
      selector: row => row.weight,
      sortable: true,
      sortFunction: (a, b) => (a.weight || 0) - (b.weight || 0),
    },
  ];

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div style={{ color: 'red' }}>Error: {error}</div>
      ) : (
        <DataTable
          columns={columns}
          data={data}
          noDataComponent="No data found."
          pagination
        />
      )}
    </div>
  );
}

export default TableComponent;