// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  animation: fadeIn 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
}

.dropdown-header {
  font-size: 14px;
  text-transform: uppercase;
  color: #6c757d;
  /* Muted color for secondary text */
}

.dropdown-item input:checked {
  accent-color: #007bff;
  /* Matches Bootstrap's primary theme */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/SettingsDropdown.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,mCAAmC;AACrC;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;;EAEA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":[".dropdown-menu {\n  animation: fadeIn 0.3s ease-in-out;\n  border: 1px solid #e0e0e0;\n}\n\n.dropdown-header {\n  font-size: 14px;\n  text-transform: uppercase;\n  color: #6c757d;\n  /* Muted color for secondary text */\n}\n\n.dropdown-item input:checked {\n  accent-color: #007bff;\n  /* Matches Bootstrap's primary theme */\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n    transform: translateY(-5px);\n  }\n\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
