import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const NavBar = ({ toggleSidebar }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ borderBottom: '1px solid #ddd', padding: '12px', position: 'sticky', top: 0, zIndex: 1030 }}>
      <div className="container-fluid d-flex align-items-center">

        {/* Sidebar Toggle Button */}
        <button
          onClick={toggleSidebar}
          style={{
            border: 'none',
            background: 'none',
            fontSize: '24px',
            marginRight: '1rem',
            zIndex: 1031, // Ensures the button stays on top
          }}
        >
          ☰
        </button>

        {/* Spacer div to center-align the search bar */}
        <div style={{ flexGrow: 1 }} />

        {/* Centered Search Bar */}
        <form className="d-flex" style={{ width: '100%', maxWidth: '600px' }}>
          <input
            className="form-control"
            type="search"
            placeholder="Search"
            aria-label="Search"
            style={{
              borderRadius: '5px',
              padding: '0.5rem 1rem',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              border: '1px solid #ddd',
            }}
          />
        </form>

        {/* Spacer div for alignment */}
        <div style={{ flexGrow: 1 }} />
      </div>
    </nav>
  );
};

export default NavBar;