import React, { useState, useEffect } from 'react';
import AssetComponent from './AssetComponent';
import CardComponent from './CardComponent';
import Toolbar from './Toolbar';
import DateRangeSelector from './DateRangeSelector';
import dayjs from 'dayjs';

const Assets = () => {
  const [stock, setStock] = useState('AAPL'); // Default stock
  const [dateRange, setDateRange] = useState('YTD'); // Default date range
  const [stocksList, setStocksList] = useState([]); // List of available stocks
  const [chartOptions, setChartOptions] = useState([]); // Options for AssetComponent
  const [selectedChartOptions, setSelectedChartOptions] = useState(['open', 'close']); // Default selected options
  const [fullscreen, setFullscreen] = useState({
    Quotes: true, // Set to true for fullscreen by default
  });

  // Fetch mock stocks list
  useEffect(() => {
    const fetchStocksList = async () => {
      setTimeout(() => {
        setStocksList(['AAPL', 'MSFT', 'GOOGL', 'AMZN', 'TSLA', 'NVDA', 'PLTR', 'OKLO', 'NTDOY']);
      }, 500);
    };

    fetchStocksList();
  }, []);

  const toggleFullscreen = (cardName) => {
    setFullscreen((prevState) => ({
      ...prevState,
      [cardName]: !prevState[cardName],
    }));
  };

  const getCardClass = (cardName) =>
    fullscreen[cardName] ? 'col-12' : 'col-12 col-lg-6 col-md-6';

  return (
    <div>
      {/* Toolbar */}
      <Toolbar
        date={dayjs().format('YYYY-MM-DD')}
        stock={stock}
        setStock={setStock}
        stockOptions={stocksList}
      />

      <div className="container p-4" style={{ marginTop: '60px' }}>
        <div className="row g-4">
          {/* Quotes Card */}
          <div className={getCardClass('Quotes')}>
            <CardComponent
              title="Asset"
              footer={<DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
              onFullscreen={() => toggleFullscreen('Quotes')}
              isFullscreen={fullscreen.Quotes}
              settingsOptions={chartOptions} // Chart options
              onSettingsChange={setSelectedChartOptions} // Update selected chart options
            >
              <AssetComponent
                stock={stock}
                dateRange={dateRange}
                setChartOptions={setChartOptions} // Hardcoded options set here
                selectedChartOptions={selectedChartOptions} // Selected options passed here
              />
            </CardComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
