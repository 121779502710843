import React from 'react';

const DateRangeSelector = ({ dateRange, setDateRange }) => (
  <div
    className="btn-group w-100 mt-3"
    role="group"
    aria-label="Date range selector"
  >
    {['1M', 'MTD', '3M', 'YTD', '1Y'].map((range) => (
      <button
        key={range}
        type="button"
        className={`btn btn-sm ${dateRange === range
          ? 'btn-primary text-white font-weight-bold'
          : 'btn-outline-primary'
          }`}
        onClick={() => setDateRange(range)}
        style={{ flex: 1 }}
      >
        {range}
      </button>
    ))}
  </div>
);

// Date range calculation function
export const calculateDateRange = (range) => {
  const today = new Date();
  const startDate = new Date();

  switch (range) {
    case 'MTD':
      startDate.setDate(1);
      break;
    case '3M':
      startDate.setMonth(today.getMonth() - 3);
      break;
    case 'YTD':
      startDate.setMonth(0);
      startDate.setDate(1);
      break;
    case '1Y':
      startDate.setFullYear(today.getFullYear() - 1);
      break;
    case '1M':
    default:
      startDate.setMonth(today.getMonth() - 1);
      break;
  }

  return {
    fromDate: startDate.toISOString().split('T')[0],
    toDate: today.toISOString().split('T')[0],
  };
};

// Export the DateRangeSelector component
export default DateRangeSelector;
