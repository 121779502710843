import React, { useState } from 'react';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';
import TransactionsComponent from './TransactionsComponent';
import PerformanceComponent from './PerformanceComponent';
import CardComponent from './CardComponent';
import Toolbar from './Toolbar';
import dayjs from 'dayjs';
import DateRangeSelector from './DateRangeSelector';

const IntervalSelector = ({ interval, setInterval }) => (
  <div
    className="btn-group w-100 mt-3"
    role="group"
    aria-label="Date range selector"
  >
    {['Weekly', 'Monthly', 'Yearly'].map((interv) => (
      <button
        key={interv}
        type="button"
        className={`btn btn-sm ${interval === interv
          ? 'btn-primary text-white font-weight-bold'
          : 'btn-outline-primary'
          }`}
        onClick={() => setInterval(interv)}
        style={{ flex: 1 }}
      >
        {interv}
      </button>
    ))}
  </div>
);

const Dashboard = () => {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [option, setOption] = useState('');
  const [stock, setStock] = useState('Total');
  const [stockOptions, setStockOptions] = useState(["Total"]);
  const [tableColumns, setTableColumns] = useState([]); // Dynamic column names
  const [selectedColumns, setSelectedColumns] = useState([]); // Selected columns for the table
  const [transactionOptions, setTransactionOptions] = useState([]); // Dynamic options for Transactions
  const [selectedTransactionOptions, setSelectedTransactionOptions] = useState([]); // Selected options for Transactions
  const [chartOptions, setChartOptions] = useState([]); // Dynamic options for ChartComponent
  const [selectedChartOptions, setSelectedChartOptions] = useState([]); // Selected options for ChartComponent
  const [dateRange, setDateRange] = useState('1M');
  const [interval, setInterval] = useState('Weekly');
  const [fullscreen, setFullscreen] = useState({
    Chart: false,
    Table: false,
    Transactions: true,
    Performance: true,
  });

  const toggleFullscreen = (cardName) => {
    setFullscreen((prevState) => ({
      ...prevState,
      [cardName]: !prevState[cardName],
    }));
  };

  const getCardClass = (cardName) =>
    fullscreen[cardName]
      ? 'col-12'
      : 'col-12 col-lg-6 col-md-6';

  return (
    <div>
      <Toolbar
        date={date}
        setDate={setDate}
        option={option}
        setOption={setOption}
        stock={stock}
        setStock={setStock}
        stockOptions={stockOptions}
      />
      <div className="container p-4" style={{ marginTop: '60px' }}>
        <div className="row g-4">
          {/* Chart Card */}
          <div className={getCardClass('Chart')}>
            <CardComponent
              title="Chart"
              footer={<DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
              onFullscreen={() => toggleFullscreen('Chart')}
              isFullscreen={fullscreen.Chart}
              settingsOptions={chartOptions} // Dynamic options for ChartComponent
              onSettingsChange={setSelectedChartOptions} // Update selected chart options
            >
              <ChartComponent
                account={option}
                stock={stock}
                dateRange={dateRange}
                setChartOptions={setChartOptions} // Update chart options dynamically
                selectedChartOptions={selectedChartOptions} // Pass selected options
              />
            </CardComponent>
          </div>

          {/* Table Card */}
          <div className={getCardClass('Table')}>
            <CardComponent
              title="Table"
              onFullscreen={() => toggleFullscreen('Table')}
              isFullscreen={fullscreen.Table}
              settingsOptions={tableColumns} // Dynamic column names
              onSettingsChange={setSelectedColumns} // Update selected columns
            >
              <TableComponent
                date={date}
                option={option}
                setStockOptions={setStockOptions} // Update stock options dynamically
                setTableColumns={setTableColumns} // Populate column names dynamically
                selectedColumns={selectedColumns} // Pass selected columns
              />
            </CardComponent>
          </div>

          {/* Performance Card */}
          <div className={getCardClass('Performance')}>
            <CardComponent
              title="Performance"
              footer={<IntervalSelector interval={interval} setInterval={setInterval} />}
              onFullscreen={() => toggleFullscreen('Performance')}
              isFullscreen={fullscreen.Performance}
              settingsOptions={transactionOptions} // Dynamic options for Transactions
              onSettingsChange={setSelectedTransactionOptions} // Update selected transaction options
            >
              <PerformanceComponent
                account={option}
                interval={interval}
              />
            </CardComponent>
          </div>

          {/* Transactions Card */}
          <div className={getCardClass('Transactions')}>
            <CardComponent
              title="Transactions"
              onFullscreen={() => toggleFullscreen('Transactions')}
              isFullscreen={fullscreen.Transactions}
              settingsOptions={transactionOptions} // Dynamic options for Transactions
              onSettingsChange={setSelectedTransactionOptions} // Update selected transaction options
            >
              <TransactionsComponent
                date={date}
                option={option}
                setTransactionOptions={setTransactionOptions} // Update transaction options dynamically
                selectedTransactionOptions={selectedTransactionOptions} // Pass selected options
              />
            </CardComponent>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
